.login-top {

    background-image: url('../image/Singleproduct.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 11rem;
    padding-top: 4rem;

}
.logintit {
    color: #920d43 !important;
    margin-right: auto;
    font-family: 'Lora';
    font-weight: 500;
}
.card-body.login-inside {
    padding: 0 50px 30px 50px;
}
p.blwtxt {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
}
a.ftxt {
    text-decoration: none;
    color: #c51461;
    font-size: 16px;
    font-weight: 400;
}
a.ftxt:hover {
    text-decoration: none;
    color: #e5a435;
    font-size: 16px;
    font-weight: 400;
}
a.signupt {
    color: #620a30;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
}
a.signupt:hover {
    color: #e5a435;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
}
.remd {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    border: none;
    color: #620a30;
}
.remd:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #c53552, #620a30);
    border: none;
    color: #ffffff;
}
