.crcStyle {
    margin-top: 9rem !important;
}
.review-section{
    background-image: url('../image/banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 6rem !important;
    margin-top: -15rem !important;
    
}
.h2Style-white {
    text-align: center !important;
    color: #ffffff !important;
    margin-top: 10px !important;
    margin-right: auto;
    font-family: 'Lora';
    font-weight: 500;
}
.rev-cont{
    padding: 20px 0px;
}
.secondFig>img {

    /* width: 15rem !important; */
    /* margin-top: -56rem; */
    /* margin-left: 40rem !important; */
    margin-top: 3rem !important;
    padding-top: 3rem !important;
    margin-left: auto !important;
    display: flex !important;
    margin-right: auto !important;
}

.header-rvw {

    text-align: center;
    /* margin-left: 35px; */
}

.header>h3 {

    font-size: 41px;
}


.rowstyle {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.imageStyle {
    width: 6rem !important;
    /* text-align: center; */
    /* display: flex; */
    /* justify-content: center; */
    margin-left: auto ! important;
    margin-right: auto !important;
}

.paragraphStyle {
    color: #ffffff;
    text-align: center;
    width: 92%;
    justify-content: center !important;
    padding: 20px 0 10px 0;
}
p.paragraphStyle-desc {
    color: white;
    text-align: center;
    padding: 20px 0 0 0;
    margin-left: auto;
    margin-right: auto;
    width: 92%;
}
.card.card-revw {
    text-align: center;
}
p.paragraphStyle-date {
    color: #fff;
    font-weight: 600;
}
.h4Style {
    display: flex;
    justify-content: center;
    color: #ffffff;
    /* margin-top: 4rem; */
    padding-top: 1rem;
}
p.paragraphStyle-user {
    color: white;
    font-weight: 600;
}
.cBtn1 {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #5a052e;
}
.cBtn1:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    color: white;
    background-image: linear-gradient(90deg, #ffcd37, #e5a435);
}
.customerbutton {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

}

.cBtn1 {
    border: 6px solid rgb(154, 36, 88) !important;
    width: 18rem;
}

.expText {

    font-weight: 500 !important;
    color: rgb(154, 36, 88) !important;
}

.modal {
    /* height: 400px; */
    /* width: 1000px; */
    /* color: red; */
}

.card-01 {

    justify-content: center !important;
}

@media(max-width:576px) {

    .paragraphStyle {

        width: 100%;

    }

    .customerMain {

        margin-top: -31rem !important;
    }


}

@media(max-width:420px) {


    .h4Style {
        font-size: 16px;
    }
.rowstyle{

    width: 100%;
}
}

@media (max-width:360px){

    .header>h3 {
        font-size: 29px;
    }

    .paragraphStyle{

        width: 96%;
    }
}