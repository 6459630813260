.CheckoutBackGround {

    background-image: url('../image/Singleproduct.png');
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    padding-top: 30rem !important;
}

.cartInnerBackGround {


    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 96%;
    margin-left: auto;
    margin-right: auto;

}
.underRow{
    padding: 15px;
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
    margin-top: 4rem;
    margin-bottom: 1rem
}
.firstcolStyles{
    padding: 15px !important;
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
     
        border: 7px solid gold;

}
.secondColStyle{
    padding: 15px !important;
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
}
.roeStylessss{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.inputStyle{
    display: flex;
    
}

.inputStyles>input{
    width: 21.5rem !important;
    height: 4rem;
    margin-right: 2rem !important;
    margin-bottom: 41px;
    border-radius: 5px;
    
}
.inputStyles>select{
    width: 21.5rem !important;
    margin-right: 2rem !important;
    height: 4rem;
    margin-bottom: 41px;
    border-radius: 5px;
}
.inputStyless>input{
    width: 45rem !important;
    height: 4rem;
    margin-bottom: 41px;
    border-radius: 5px;
}
.payment_box_payment_method_razorpay{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-size: .92em;
    border-radius: 2px;
    line-height: 1.5;
    background-color: #dfdcde;
    color: #515151;
}
.btncheckstyle{
    float: right;
    width: 11rem;
    height: 3rem;
    background-color: #f8b407;
    border-radius: 11px;
    border: 7px solid #962d54;
    font-size: 24px;
    font-weight: 400;
}





