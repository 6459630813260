.enquirystyle{


    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 97%;
    height:200px
}

@media(max-width:576px){

    .enquirystyle{

        border-Radius: 23px 23px 0px 0px !important;
        margin-top: -2rem !important;
    }
}