.psBackGround100 {

    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 5rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 96%;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;

}
.psBackGround100::after{
content: "";
background: url("../image/flw-ck.png") no-repeat;
top: 10px;
right: 5px;
position: absolute;
}

.viewPriceStyle {
    padding: 15px;
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);

}

.backfrompackage {

    padding-bottom: 13px;
    color: #620a30;
    font-size: 21px;
    font-family: 'Lora';
    font-weight: 600;
}
hr:not([size]) {
    height: 0px;
}

.viewSummeryStyle{

    padding: 15px;
    margin-top: 25px;
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
}
.container.start-packs {
    padding-top: 17px;
}
.puja-packages {
    font-weight: 600;
    font-size: 17px;
    background: -webkit-linear-gradient(#620a30, #df126b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Lato';
}
li.packageLI {
    list-style: none;
}
.bi-check2-circle::before {
    content: "\f270";
    position: absolute;
    padding-top: 3px;
    color: #d11566;
    padding: -12px;
    margin-left: -23px;
}
.inclusion{

    border-right: 1px dashed #e3a035
}
span.packagePrice {
    font-size: 17px;
    font-family: 'Lato';
    font-weight: 600;
}
.singlebs {
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #620a30;
    /* padding: 6px 39px; */
    border-radius: 6px;
    border: 1px solid;

}