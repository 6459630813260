.eventsHeroBackGround{

    background-image: url('../image/Singleproduct.png');
    background-size: 100% 100%;
    padding-top: 32rem !important;
}
.eventsInnerHeroBackGround{

    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 97% !important;
    height:200px !important
}

.psBackGround3{

    background: #ffffff !important;
    border-Radius: 100px 100px 0px 0px !important;
    /* background-size: cover; */
    margin-top: -5rem !important;
    /* position: relative !important; */
    width: 97% !important;

    height: 200px;
}
.h2Style-ev {
    color: #620a30 !important;
    font-size: 30px !important;
    font-family: 'Lora';
    font-weight: 500;
    background: -webkit-linear-gradient(#620a30, #cd0058 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.psBackGro {
    padding-bottom: 50px;
}

@media(max-width:576px){

    .psBackGround3{

        border-Radius: 23px 23px 0px 0px !important;
        margin-top: -2rem !important;
    }
}