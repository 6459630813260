.navStyle{

    position: fixed !important;
    width: 100% !important;
}





#navigation {
    color: white !important;

}

.novo>img {

    width: 14rem !important;
    margin-left: 1rem !important;
}

.nova>li>a {


    color: #ffffff !important;
}


#masthead {
    z-index: 10;
    position: fixed;
    /* width: 100%; */
    height: 100px;
    background:transparent;
    transition: 0.3s ease all;
}

nav {
    padding-top: 20px;
}

nav ul {
    display: inline;
}

nav li {
    display: inline;
}

nav li a {
    margin: 0 5px;
    color: #fff;
}

nav .anchors a {
    padding: 10px 15px;
}

nav .anchors a:hover {
    color: #df691a;
    text-decoration: none;
}

hgroup {
    position: relative;
    transition: 0.15s ease all;
    padding-top: 30px;
}

hgroup h1 {
    font-weight: 300;
    font-size: 4.5em;
}

hgroup h2 {
    font-size: 1.25em;
}

#masthead.scrolled {
    height: 100px;
    background-color: #620a30 !important;
    z-index: 9999;
}

#masthead.scrolled hgroup {
    opacity: 0;
    transform: translateY(-100px);
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 9px;
  }
  
  .label-warning[href],
  .badge-warning[href] {
    background-color: #c67605;
  }
  #lblCartCount {
      font-size: 12px;
      background: #f5be36;
      color: #fff;
      padding: 0 5px;
      vertical-align: top;
      margin-left: -16px
      ; 
  }

/* @media(max-width:576px){

    #navbarSupportedContent{
        background-color: #620a30 !important;
        height: 100% 100%;

    }
    #masthead.scrolled{
        background:none !important;
        margin-top:0
        
    }

} */
/* #navbarSupportedContent{

    background-color:#962d54 !important;
} */

