.SingleProductImage {

    
}
.all-productpage{
    background-image: url('../image/Singleproduct.png');
    height: 100% !important;
    width: 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding-bottom: 4em !important;
    padding-top: 10em !important;
    color: #ffffff !important;
    font-size: 20px !important;

}
.product-breadcrumbs {
    padding-left: 23px;
}
.product-price {
    display: flex;
    font-weight: 600;
    font-size: 17px;
    background: -webkit-linear-gradient(#f5be36, #705000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Lato';
}
.singleRowStyle {
    padding-top: 1em !important;
    
}
.drdivStyle {
    padding-right: 21px;
}

.expText{

    float: left;
}


.pst {
    display: flex !important;
    padding-top: 9rem !important;
    padding-left: 2rem !important;
}

.DespStyle {
    overflow: hidden;
    padding-right: 37px;
    background: #fff;
    clear: both;
    border-radius: 10px;
    padding-bottom: 20px;
    
}

.spiStyle>img{

    background:none !important;
    border: 3px solid  #962d54 !important;
    border-radius: 64px !important;
    width: 100%;
}

.ptiStyle{

padding-left: 1rem;
}
span.price-gp {
    padding: 0 5px;
}
.psStyle{
    color: #620a30 !important;
    font-size: 30px !important;
    font-family: 'Lora';
    font-weight: 500;
    background: -webkit-linear-gradient(#620a30, #cd0058 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btnsps{

    /* margin-left: auto !important;
    margin-right: auto !important; */
    /* display: flex !important; */
    margin-top: -6rem !important;
    border: 6px solid #962d54 !important;
    border-radius: 12px !important;
    margin-left:36em !important;
}

.modalStyle{

   
    width: 100% !important;
}

.modal-content {
    border: 6px solid #962d54 !important;
    border-radius: 20px !important;
    width: 1169px !important;
    margin-top: 9rem !important;
}

.modal.fade .modal-dialog{

    margin-left: 320px !important;
}

.titleStyle{

    color: #e3a035 !important;

}

.respriceStyle{

    color: #e3a035 !important;
}


.review-p {
    padding: 41px 0;
}
.psoc {
    text-align: center;
}
a.picon {
    padding: 10px;
}
a.picon>i {
    font-size: 20px;
    /* border: 1px solid; */
    padding: 8px 12px;
    border-radius: 50%;
    color: #e3a035;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
}
a.picon>i:hover {
    font-size: 20px;
    border: none;
    padding: 8px 12px;
    border-radius: 50%;
    color: #620a30;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
}


/* .addToCrtStyle a:link>{

  text-decoration: none !important;

} */

.sprowStyles{

    padding-bottom: 5rem !important;
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
/* .modal-header{

    border: none !important;
} */

.modal-footer{

         border-top: none;
}

.pujasinge-desc {
    padding-bottom: 23px;
    font-size: 16px;
    font-family: 'Lato';
    text-align: justify;
    color: #585858;
}
li.ldm {
    padding-bottom: 0px;
    font-size: 16px;
    font-family: 'Lato'!important;
    font-style: normal;
    text-align: justify;
    color: #585858;
    list-style: none;
}
.submitStyle.ml-auto {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #ffcd37, #e5a435);
    color: #610a2d;
}
.submitStyle.ml-auto:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #ffffff;
}
.whenandwhy{
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    float: left;
    width: 49%;
    padding: 15px 10px 0 0;
}
h4.pkd-det {
    padding-bottom: 13px;
    color: #620a30;
    font-size: 20px;
    font-family: 'Lora';
    font-weight: 600;
}
.ourpromise{

    border-top: 1px solid #ccc;
    float: left;
    margin-left: 0;
    width: 51.001%;
    padding: 15px 0 0 15px;
}

.location-description {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    float: left;
    padding: 8px;
    text-align: left;
    width: 44%;
}
.viewprice-package {
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #ffcd37, #e5a435);
    color: #620a30;
    padding: 6px 39px;
    border-radius: 6px;
}
.viewprice-package:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #ffffff;
    padding: 6px 39px;
    border-radius: 6px;
    border: 2px solid;
}
.location_selection{

    display: none !important;
}

.locationselection {
    display: inline-block;
    float: right;
    width: 47%;
}
.produc-selectlocation {
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    margin-top: 2px;
    padding-left: 18px;
}


.locationselection select {
    height: 35px;
    padding: 0 8px;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    width: 250px;
    margin-bottom: 10px;
    float: right;
}

.locationselection span.singleloc {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    float: right;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 0;
    padding: 8px 10px;
    width: 70%;
}
/* .viewprice-package {
    background:#f5bc36!important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
    float: right;
    padding: 9px 12px !important;
    border-radius: 5px !important;
} */

.woocommerce-tabs{
    clear: both;
}

.woocommerce-tabs.wc-tabs-wrapper {

    margin-top: 20px;
    width: 70%;
    float: left;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
    list-style: none;
    padding: 0 0 0 1em;
    margin: 0 0 1.618em;
    overflow: hidden;
    position: relative;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}
@media(max-width:720px){

    .pst {
        display: grid !important;
        padding-top: 9rem !important;
        padding-left: 0rem !important;
    }
    .psStyle {
        color: #620a30 !important;
        font-size: 23px !important;
    }
    .DespStyle{
        padding-right: 0px;
    }
    .drdivStyle {
        padding-right: 0px;
    }
    .whenandwhy {
        width: 100%;
        border: none;
    }
    .ourpromise {
        width: 100%;
    }
    .location-description{
        width: 100%;
    }
    .imageDiv1 {
        padding: 39px 0 10px 0;
    }
    .all-productpage {
        background-size: cover !important;
        padding-bottom: 2em !important;
        padding-top: 6em !important;
      
    }
    .SingleProductSecond {
        background: #ffffff !important;
        border-Radius: 74px 69px 0px 0px !important;
        margin-top: -3rem !important;
        width: 96% !important;
        padding-top: 5rem !important;
        height: auto;
    }
    .single-product-tabs {
        padding: 50px 0;
    }
    
    .locationselection{
        width: 100%;
        padding-top: 20px;
    }
    .product-price {
        display: flex;
        font-weight: 600;
        font-size: 16px;
    }
    .product-breadcrumbs {
        padding-left: 0px;
        font-size: 15px;
    }
    .spiStyle>img{

width:100% !important

    }

    .btnsps{

        width: 269px !important;
    }
    .breadcrumbStyle{

        padding-left: 3em !important;
        font-family: 'lato';
    }

    .btnsps{

        margin-top: 1rem !important;
        border: 6px solid #962d54 !important;
        border-radius: 12px !important;
        margin-left: 3em !important;
    }

    .modal.fade .modal-dialog {
        margin-left: -2px !important;
    }

    .modal-content {
        border: 6px solid #962d54 !important;
        border-radius: 20px !important;
        width: 578px !important;
        margin-top: 6rem !important;
    }

}
