.footer {
    background-image: linear-gradient(180deg, white 50%, #ffdfe9 50%);
}
/* section.footer::after{
    content: "";
    background: url("../image/flower.png") no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 500px;
    height: 400px;
} */

.headerDiv {

    margin-top: 12rem;
}
.footer-link {
    padding-top: 32px;
    align-items: left;
}
.novo-footer>img {
    font-size: 20px;
    width: 185px;
}

.mains {
    background: rgb(98, 10, 46);
    width: 100% !important;
    margin-top: 4rem !important;
    border-radius: 47px !important;
    position: relative !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 33px 9px 20px 9px;
}

.h2style {
    text-align: center;
    margin-top: 1rem;
    color: rgb(90, 5, 46) !important;
}

.imageclass {
    margin-left: auto !important;
    margin-right: auto !important;
    /* text-align: center; */
    padding-left: auto;
    display: flex;
    padding-top: 6rem !important;
}

.editable_card {

    background: none !important;
    border: none !important;
}

.editable_card>img {

    border-radius: 16%;
}

.c_card {

    color: #f5be36;
    text-align: center;
}

.c_text {
    color: #ffffff;
    text-align: center;

}

.innercard_title_title {

    text-align: center;
    height: 77px;
    width: 77px;
    padding: 10px;
    background-color: #620a2e;
    border-radius: 50%;
    position: absolute;
    bottom: 42%;
    right: 3%;
}

.letters {
    color: #fecb37
}

.parents {
    width: 100%;
    height: auto;
    background-color: aqua;
    position: inline-block;
}

.turn {

    background: #e7d8cb;
    width: 100%;
    height: auto;
    padding-top: 24rem !important;
    margin-top: -21rem;

}


.footClassStyle>h3 {
    /* font-size: 2em; */
    color: #620a2e;
    text-align: center;

}
.razorpayimg {
    width: 127px;
}
p.foopay-img {
    font-weight: 600;
    font-family: 'Lato';
}
.foo-text {
  
    color: #620a2e;
    text-align: left;
    padding-top: 10px;
    font-family: 'Lato';
}

.foo-lnk {
    color: #620a2e;
    font-family: 'Lora';
    font-size: 25px;
    font-weight: 500;
}
.foo-linkUL {
    padding: 0;
   
}
.foo-copyright {
    padding-bottom: 23px;
    padding-top: 11px;
}
.foo-linkLI>a {
    text-decoration: none;
    color: #620a2e;
}
.foo-linkLI>a:hover {
    text-decoration: none;
    color: #f1b736;
    font-weight: 600;
}
ul.foomainUL {
    padding: 0;
}
li.list-unstyled.d-flex>i {
    /* margin: 0; */
    font-size: 20px;
    padding: 0 10px 10px 0;
}
span.fo-mail {
    font-weight: 600;
    padding-top: 4px;
}
.foo-socicon{
    text-align: right;
}
a.soc-cl {
    padding: 9px 12px;
    font-size: 17px;
    color: #620a30;
    border: 2px solid #f8c236;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 10px;
}
/* .footerOfficeAddress>h3{
    color: #a89e96;
    text-align: center;
}
.footerOfficeAddress>p{

    width: 100%;
    color: #a89e96;
    text-align: center;

}

.footerPujaStyle>h3{
    color: #a89e96;
    text-align: center;

}
.footerPujaStyle>p{
    width:100%;
    color: #a89e96;

    text-align: center;
    
}

.footerReachUsStyle>h3{
    color: #a89e96;
    text-align: center;


}

.footerReachUsStyle>p{
    width: 100%;
    color: #a89e96;
    text-align: center;

} */

.footerStyles{
    color: #a89e96;

}

@media(width:820px){

.innercard_title_title{

    
    margin-left: 32rem;

}

}
@media(max-width:576px) {


    .innercard_title_title{

        margin-left: 22rem;
    }
    
}

@media(max-width:450px){

    .innercard_title_title{
        margin-left: 13rem;
    }
}

@media(max-width:420px){



}


