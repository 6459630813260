.cStyle {
    background: #ffffff !important;
    border-Radius: 100px 100px 0px 0px !important;
    /* background-size: cover; */
    margin-top: -5rem !important;
    /* position: relative !important; */
    width: 95% !important;

}
.top-bk {
    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 96%;
    height: 90px;
}
.col-lg-4.col-sm-12.colstyle {
    text-align: center;
    font-family: 'Lora', serif;
}
p.hStyle-para {
    color: #5a052e;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Lato';
}
.rStyle {
    width: 100% !important;
    padding-top: 5rem !important;
    display: flex !important;
    /* justify-content: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
}

.hStyle {
    color: #5a052e !important;


}

.h2Style {
    text-align: center !important;
    color: #920d43 !important;
    margin-top: 4rem !important;
    margin-right: auto;
    font-family: 'Lora';
    font-weight: 500;

}

.colstyle {
    /* padding-left: 15rem !important; */
}

.pStyle {
    width: 100% !important;
    text-align: center !important;
    margin-top: 3rem !important;
    color: #2e2e2e !important;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Lato';
}

.imStyle {

    padding-left: 8rem !important;
}

.imsStyle {
    padding-left: 17rem !important;
}

/* .ihero{

    width: 20rem !important;
    margin-left: 4rem !important;
}
.ihero3{
    width: 12% !important;
    margin-left: 21rem !important;
}
.ihero2{

    width: 14rem !important;
    margin-left: 16rem !important;
} */    
@media(max-width: 720px){
.top-bk {
    background: #ffffff !important;
    margin-top: -3rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 97%;
    height: 52px;
}
}

@media(max-width:850px){

    /* .colstyle{

        padding-left: 15rem !important;
    } */

    .imStyle {
        padding-left: 15rem !important;
    }
}
@media (max-width:576px) {

    .cStyle {

        margin-top: -2rem !important;
        border-Radius: 20px 20px 0px 0px !important;
        width: 100% !important
    }

    /* .colstyle {
        padding-left: 12rem !important;
    } */

    .imStyle {
        padding-left: 11rem !important;
    }

    .imsStyle {
        padding-left: 11rem !important;
    }

}

@media(max-width:450px) {

    /* .colstyle {
        padding-left: 6rem !important;
    } */

    .colstyle1,
    .colstyle2 {

        margin-left: -5rem;
    }

    .colstyle1>h1,
    .colstyle2 {

        margin-left: -5rem;
    }

    .imStyle {

        width: 19rem !important;
        padding-left: 8rem !important;
    }

    .hsStyle {
        padding-left: 3rem !important;
    }
    .hssStyle{

        padding-left: 2rem;
    }

    .imsStyle{
        width: 20rem;
        padding-left: 8rem !important;
    }
}