.headings{
    margin-top: 6rem !important;
}
.main_body{
    text-align: center;
    color:#620a2e
}
.main_paragraph{
    font-family: 'Lato';
    text-align: center;
    color: #434343;
    font-weight: 500;
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}
.nextsection {
    margin-top: 80px;
}
.cardbody{

    
    /* display: flex; */
    /* justify-content: center; */
    margin-left: 2rem !important;
}
.cards{
    margin-left: 2rem !important;
}

.changeimagesize{

    width: 5rem !important;
    margin-top: 7rem !important;
    margin-left:8rem;
}

.card_body-work{

    border-radius: 53px!important;
    width: 100%;
    height: 100% !important;
    border: 3px solid #620a2e !important;
    font-weight: 200;

}
.innercard_title>.letter {
    /* color: #f5bc36; */
    font-size: 25px;
    font-family: 'Lora';
    font-weight: 800;
    background: -webkit-linear-gradient(#ffcd37, #e5a435);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.inner_cardbody{
 
    height: 6rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -7%;
    text-align: center;
}

.innercard_title{

    text-align: center;
    height: 50px;
    width: 50px;
    padding: 10px;
    background-color:#620a2e;
    border-radius: 50%;
    display: inline-block;
}
.innercard_title>.letter{

    color:#f5bc36
}
.inner_inner_card{
    text-align: center;
}
.inner_inner_card.img{
    width: 100px;
    margin-top: -2rem;
}
.inner_inner_body{
    margin-left: auto;
    margin-right: auto
}
.inner_inner_header{
    text-align: center;
    color:#565656;
    padding-top: 18px;
}
.imagess{
    margin-top: 0rem;
    text-align: center;
}
.arrow>img {
    /* position: absolute; */
    margin-top: -14rem;
    margin-left: -7rem;
}


@media(width:820px){

    .inner_cardbody{

        margin-top: -5%;
    }

    .hsssStyle{

        margin-bottom: 42px;
    }
    .inner_inner_card{

        padding-left: 16.5rem;

    }

    .imagess{

        padding-left: 17rem;

    }
}
@media(max-width:576px){

    .vwStyle{

        margin-top: 6rem;
        margin-left: 1rem;
    }

    .vwsStyle {
        margin-left: 2rem;
        margin-top: 6rem;

    } 

    .inner_inner_card {
        /* width: 7em; */
        /* height: 100px; */
        padding-left: 10.5rem;
        /* margin-right: auto; */
    }

    /* .imagess {
        text-allign: center;
         justify-content: center;
        padding-left: 13rem;
        padding-top: -10rem;
        margin-top: -1rem;
    } */

    /* .arrow>img {
    
        margin-top: -6rem;

    } */
    .inner_inner_body{
        margin-bottom: 46px !important;
    }
    .imagess>img{

        width: 118px !important;
    }
}

@media(max-width:450px){
    .inner_inner_card{

        padding-left: 6.5rem;
    }

    .imagess{

        padding-left: 5rem;
    }

    .vwsStyle{

        margin-left: 1rem;
    }
}

@media(max-width:360px){

    .vwStyle{

        margin-left: 0rem;
    }
}