/* .colStyle{

    display:flex !important;
justify-Content:center !important;
 width:100% !important;
}

.card-01{


border:none !important;
background:none !important;
width: 21rem !important;
margin-Bottom:1rem !important;
} */

/* .backGround{

    background-image: url('../image/background_mid.jpg');
    background-repeat: no-repeat !important;
    width:100% !important;
    height:100% !important;
    margin-top: 4rem!important;  
} */


.pImageStyle {

    width: 100% !important;
    height: 100% !important;
    background: #ffffff !important;
    border-radius: 47px !important;
    border: 4px solid #620a30 !important;
}

.pCardStyle {


    border: none !important;
    background: none !important;
    width: 100% !important;
    margin-bottom: 2rem
}

.pColStyle {
    /* width: 25% !important; */
    /* width:24rem !important; */
    /* margin-top: 11rem !important; */
}

.crddata {

    text-decoration: none !important;
    color: #ffffff !important;
    background: radial-gradient(#c53552, #620a30);
    /* position: absolute; */
    width: 71%;
    /* height: 2rem; */
    padding: 6px 0px;
    margin-top: -3.5rem;
    border-radius: 77px 77px 0px 0px;
    display: flex;
    justify-content: center;
    margin-left: 15%;
    margin-bottom: 9px;
}
.crddata:hover{
    font-family: 'Lato', sans-serif!important;
     font-size: 16px;
     font-weight: 500;
     background-image: linear-gradient(90deg, #e5a435, #ffcd37);
     cursor: pointer;
     border: none;
     color: #8e1d3f!important;
 }
.crddata>.classStyle {

    /* padding-top: 1rem; */
    justify-content: center;
    display: flex;
}

@media(width:820px){

    .crddata{
        width:31rem !important

    }
}

@media (max-width:576px) {

    .crddata {

        margin-left: 118px;
    }
}



@media (max-width:450px) {

    .crddata {

        margin-left: 56px !important;
        width: 156px !important;
        margin-top: -67px !important;
    }
}