.acdStyle{
/* 
    background-color: #e3a035 !important;
    border:3px solid #962d54 !important; */
    border-radius: 4px !important;
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #962d54;
    
    }



    .AddTOCartStyle{
        display: flex !important;
        justify-content: end !important;
        margin-right: 237px !important;
        margin-top: 2rem !important;
     
    }

    .CartAcdStyle{

        background-color: #e3a035 !important ;
        border:3px solid #962d54 !important;
    }