.pujaServiceRowolStyle{

    background-image: url('../image/banner1.png');
    background-size: 100% 100%;
    padding-top: 9rem !important;
}

.pujaServiceCardStyle{
    background: none !important;
    border: none !important;
}

.pujaServiceCardStyle>img{

    border: 4px solid rgb(13 110 253 / 25%) !important;
    border-radius: 138px !important;
    background: #ffffff !important;
}

.pujaServiceColStyle{

    margin-bottom: 2rem !important;
}

.psBackGround1{
    background-image: url('../image/Singleproduct.png');
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    padding-top: 30rem !important;
}


.psBackGround3{
    width: 100% !important;
    padding-top: 14rem !important;
    /* padding-left: 9rem !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    
}

.psbanner-services{

    background: #ffffff !important;
    margin-top: -5rem !important;
    padding-top: 1rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 96%;
    padding: 50px 0 0px 0;
   
}
.servprod {
    padding-top: 60px;
    padding-bottom: 254px;
    background-image: url(../image/background_serv.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
}
.servprod {
    padding-top: 60px;
}

@media(max-width:720px){
    .serviceheaders {
        padding-bottom: 6rem;
        padding-top: 9rem;
    }
    .psbanner-services {
        margin-top: -3rem !important;
        padding-top: 1rem !important;
        border-radius: 61px 61px 0px 0px !important;
        width: 96%;
        padding: 18px 0 280px 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
    .finder-button{
        width: 100%;
    }

}
@media(max-width:450px){
.psBackGround2{

    border-radius: 21px 24px 0px 0px !important;
    margin-top: -3rem !important;
}

}

