.singleServiceBackground{


    background: url('../image/servicepage1.png');
    background-size: 100% 100%;
  
}
.singleServiceBackground{

    background: url('../image/imageFrame.png');
    background-size: 100% 100%;
}