.cartBackGround {
    background-image: url('../image/Singleproduct.png');
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    padding-top: 30rem !important;
    padding-left: 2rem !important
}

.cartRowGround {
    
    background: #ffffff !important;
    margin-top: -5rem !important;
    /* padding-top: 1rem !important; */
    border-radius: 100px 100px 0px 0px !important;
    width: 97%;
    height: 100vh ;

}

.cartItemStyle>img {
    height: 100px !important;
    width: 100px !important;
    /* margin-bottom: 2rem !important; */
}
.cartItemcolStyle{

width:100%;
margin-top: 4rem;
    
}
.cartItemRowStyle {
    background: #ffffff;
    width: 50% !important;
    margin: 0 !important
}

.cartItemRowsStyle {

    width: 50% !important;
}

.cartItemRowColStyle {

    padding-left: 3rem !important;
    color: #e3a035 !important;
    font-weight: bold;
}

.cartItemRowPriceColStyle {
    padding-left: 8rem !important;
    color: #e3a035 !important;
    font-weight: bold;
}

.rightcart {
    line-height: 27px;
}
.check-but {
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #620a30;
    padding: 6px 39px;
    border-radius: 6px;
    border: 2px solid #620a30;
    margin-top: 30px;
}
.check-but:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #c53552, #620a30);
    border: none;
    color: #ffffff;
    border: 2px solid #620a30;
}
.trace-but {
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    color: #620a30;
    border: none;
}
.trace-but:hover{
    background-image: linear-gradient(90deg, #c53552, #620a30);
    color: white;
}
.getPrice {

    padding-top: 2rem !important;
    color: #e3a035 !important;
    font-weight: bold;
}

.cartItemBtnStyle>Button {

    margin-top: 27px !important;
    background-color: #e3a035 !important;
    border: 4px solid #962d54 !important;
    border-radius: 10px !important
}

.cartdel-but {
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    border: 2px solid #620a30;
}
button.cartdel-but:hover {
    background-image: linear-gradient(90deg, #c53552, #620a30);
    border: 2px solid #620a30;
}
.cartRowStyle {

    background: #ffffff !important;
    border-Radius: 100px 100px 0px 0px !important;
    /* background-size: cover; */

}

/* .inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
    }
    
    .inputGroup label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    }
    
    .inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: #5562eb;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
    }
    
    .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%; */
/* transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
    }
    
    .inputGroup input:checked ~ label {
    color: #fff;
    }
    
    .inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
    }
    
    .inputGroup input:checked ~ label:after {
    background-color: #54e0c7;
    border-color: #54e0c7;
    }
     */
/* .inputGroup input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    } */

/* .form {
    padding: 0 16px;
    max-width: 550px;
    margin: 50px auto;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    } */
/*     
    body {
    background-color: #d1d7dc;
    font-family: 'Fira Sans', sans-serif;
    } */

/* *,
    *::before,
    *::after {
    box-sizing: inherit;
    }
     */
/* html {
    box-sizing: border-box;
    } */

/* code {
    background-color: #9aa3ac;
    padding: 0 8px;
    } */


.colcartStyle{

    margin-left: 44em !important;
    margin-top: 6rem !important;
}

.cartItemcard2Style{

    margin-left: 11rem !important;
    width:100% !important
}

.TableStyle>tbody{

    vertical-align: middle !important;
}

@media (width:820px){

    .colcartStyle{

        margin-left: 0em  !important;
    }
}
@media(max-width:576px){

    .colcartStyle {

        margin-left: 0rem !important;
    }
    .cartItemcard2Style{
        margin-left: 0rem !important;
    }


    .cartRowGround{

        border-Radius: 23px 23px 0px 0px !important;
        margin-top: -2rem !important;
    }
}