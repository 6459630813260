.psBackGround10{

    background: #ffffff !important;
    border-Radius: 100px 100px 0px 0px !important;
    /* background-size: cover; */
    margin-top: -5rem !important;
    /* position: relative !important; */
    width: 97% !important;
    height:500px
}

.psBackGround10>h1{

    display: flex;
    justify-content: center;
    padding-top: 10rem;
    color:#a89e96 !important
}

.psBackGround10>a{
    display: flex !important;
    justify-content: center !important;
    text-decoration: none !important;
    color:#fecb37 !important;
    font-size: xx-large;
    font-weight: bolder;
}
.cart-cont {
    padding-bottom: 50px;
    text-align: center;
}
a.c-sel {
    text-decoration: none;
    font-size: 19px;
    font-family: 'Lato';
    color: #df9000;
}
