.contactStyle{

    /* background-color: #e7d8cb ; */
    /* background:#e7d8cb; */

    background: #ffffff !important;
    margin-top: -5rem !important;
    /* padding-top: 1rem !important; */
    border-radius: 100px 100px 0px 0px !important;
    width: 97%;
    height: auto ;
}
.contact-head {
    color: #620a30 !important;
    font-size: 30px !important;
    font-family: 'Lora';
    font-weight: 500;
    background: -webkit-linear-gradient(#620a30, #cd0058 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.con-txt {
    font-size: 17px;
    font-family: 'Lato';
    /* width: 229px; */
}


  .contact-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .contact-cont .card {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    border-radius: 5px;
    overflow: hidden;
  }
  .contact-cont .card:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, .1);
    pointer-events: none;
  }
  
  .contact-cont .circle {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: 700;
    transition: .5s; 
  } 
  
  .contact-cont .card .c-1{
    background: #e84393;
    box-shadow: 0 0 0 0 #e84393;
  }
  
  .contact-cont .card .c-2{
    background: #6c5ce7;
    box-shadow: 0 0 0 0 #6c5ce7;
  }
  
  .contact-cont .card .c-3{
    background: #f9ca24;
    box-shadow: 0 0 0 0 #f9ca24;
  }
  
  .contact-cont .card:hover .c-1{
    box-shadow: 0 0 0 300px #e84393;
  }
  
  .contact-cont .card:hover .c-2{
    box-shadow: 0 0 0 300px #6c5ce7;
  }
  
  .contact-cont .card:hover .c-3{
    box-shadow: 0 0 0 300px #f9ca24;
  }
  
  .contact-cont .card .info {
    position: relative;
    z-index: 1;
    transition: .5s;
  }
  
  .contact-cont .card:hover .info {
    color: #fff;
  }
  
  .contact-cont .card .info h3 {
    margin: 20px 0;
    font-size: 1.2rem;
  }
  
  .contact-cont .card .info p {
    margin: 0;
    padding: 0;
  }
  
  .contact-cont .card .info a {
    margin-top: 20px;
    padding: 10px 15px;
    display: inline-block;
    background: #fff;
    text-decoration: none;
    font-size: .8rem;
    font-weight: 500;
    border-radius: 2px;
    color: #000;
    cursor: pointer;
     box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  }
@media(max-width:576px){

    .contactStyle{

        border-Radius: 23px 23px 0px 0px !important;
        margin-top: -2rem !important;
    }
}