.singleDown{
    display: flex;
   
}

.squares{
    background-color: #f7f7f7 !important;
    /* justify-content: center; */
    text-align: center;
    border:1px solid black

}
.Product-enquiry {
    padding: 27px;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    border-radius: 24px;
    background-color: white;
}
span.enq-resp {
    font-size: 16px;
    font-family: 'Lato';
    color: #620a30;
    font-weight: bold;
}
.single-product-tabs {
    padding: 70px 0;
    background: #f7f7f7;
}
.SingleProductSecond {

    background: #ffffff !important;
    border-Radius: 100px 100px 0px 0px !important;
    margin-top: -5rem !important;
    padding-bottom: 50px;
    width: 96% !important;
    padding-top: 5rem !important;
    height:auto;
}

.product-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-size: 17px;
    color: #620a30;
    font-weight: 500;
}
.product-tabs>.li> #uncontrolled-tab-example-tab-profile{
    font-size: 17px;
    color: #1a1a1a;
    font-weight: 500;

}
.product-main-tab {
    padding-left: 10px;
    padding-right: 30px;
}
.product-tab-content {
    font-size: 16px;
    padding: 26px;
    font-family: 'Lato';
    color: #585858!important;
}
.product-tabs .nav-link:hover{
    color: #f5bd36;
}
.product-tabs .nav-link{
    color: #000;
}
.single-prod-enq {
    padding-top: 13px;
}
label.form-label {
    display: none;
}
.form-control {
    padding: 8px;
    margin-bottom: 18px;
}
.enq-form {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    border: none;
    color: #620a30;
}
.enq-form:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #ffcd37, #e5a435);
    color: #ffffff;
    border: none;
}