/* .backgroundImage{
    background-image: url('../image/background_mid.jpg');
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center center;
    height: auto;
  
} */
.product-fetures {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 60vh;
  background-image: url('../image/background_mid.jpg');
  background-repeat: no-repeat !important;
    background-position: center center;
    align-items: center;
}
.serv-but {
  font-family: 'Lato', sans-serif!important;
  font-size: 17px;
  font-weight: 600;
  background-image: linear-gradient(90deg, #e5a435, #ffcd37);
  cursor: pointer;
  border: none;
  color: #8e1d3f;
  padding: 10px 40px;
}


.h2Style-2 {
  text-align: center !important;
  color: #920d43 !important;
  margin-top: 10px !important;
  margin-right: auto;
  font-family: 'Lora';
  font-weight: 500;
}
/* .fet-puja-section{
  text-align: center;
  padding-top: 50px;
  padding-bottom: 60vh;
  background-image: url('../image/background_mid.jpg');
    background-repeat: no-repeat !important;
    background-size: cover; 
    background-position: center center;
    align-items: center;
  
} */
.fetured-pujas{
 /* width: 1000px; */
 height: auto;
 padding-top: 50px;
 /* padding-bottom: 50px; */
 text-align: center;
}

.frowStyle{
  width: 100% !important;
  padding-top: 4rem !important;
  /* padding-left: 9rem !important; */
  margin-left: auto !important;
  margin-right: auto !important;
}
@media(max-width: 720px){
  .backgroundImage{
    padding-top: 0px;
  }
  .frowStyle{
    padding-top: 0px!important;
  }
}
 @media(max-width:576px){

  .backgroundImage {
    /* background-image: url(http://localhost:3000/static/media/background_mid.b93d04b….jpg); */
    background-image: none;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    margin-top: 4rem!important;
    padding-bottom: 39rem !important;
}
 }