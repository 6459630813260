:root{
font-family: 'Lato', sans-serif;
font-family: 'Lora', serif;

}
.banner-images {
    min-height: 120vh;
    background-image: url(../image/banner1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    display: flex;
    align-items: flex-end;
    padding-bottom: 120px;
    font-size: 27px;
}
.search-but {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
}
.search-but:hover{
font-family: 'Lato', sans-serif!important;
font-size: 17px;
font-weight: 600;
background-image: linear-gradient(90deg, #ffcd37, #e5a435);
color: white;
}
h5.bdwn-txt {
    font-family: 'Lora', serif;
    color: white;
    text-align: center;
    padding-top: 30px;
}


.hbrStyle {

    margin-left: auto !important;
    margin-right: auto !important;
}

.herorowStyle {
    /* padding-top: 33em !important; */
    margin-left: auto !important;
    margin-right: auto !important;

}

.selectStyle {

    border: 10px solid #962d54 !important;
    border-radius: 13px !important;
}

.submitStyle {

    width: 21.6rem !important;
    border: 10px solid #962d54 !important;
    border-radius: 13px !important;
}

.optionStyle {

    text-align: center !important;
    font-weight: bolder !important;
    color: #434343 !important;
}

.heroColStyle {

    /* width:21% !important; */
}

/* @media (max-width:576px) {

    .images {

        background-size: 100%;
        background-repeat: no-repeat;
        height:107vh !important;

    }
    .herorowStyle{

        padding-top: 6em  !important;
    }
} */


@media(max-width:850px) {
    
    .selectStyle {
        margin-bottom: 15px
    }

    .submitStyle{

        width:100% !important
    }
    .serviceHeaderColStyle {
        margin-right: 0rem !important;
        margin-bottom: 1rem !important;
    }

}

@media (max-width: 767px){
    .banner-images {
        min-height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        display: flex;
        align-items: flex-end;
        padding-bottom: 20px;
        font-size: 27px;
    }
    .Banner-form {
        padding-top: 130px;
    }
}

@media (max-width:576px) {

    .images {
        /* background-size: 100% 100% !important;
        padding-bottom: 10em !important; */

        width: 100vw !important;
    }


    .heroColStyle {
        /* width: 21% !important; */
        /* padding-top:32em !important; */
        margin-bottom: 14px !important;
    }

    .submitStyle {
        width: 100% !important;

    }

    .herorowStyle {

        padding-top: 42em !important;
    }

    .selectStyle {

        margin-bottom: 1rem !important;
    }
}


@media(max-width:450px) {
    .submitStyle {
        width: 100% !important
    }

}