.serviceheaders {

    background-image: url('../image/Singleproduct.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 11rem;
    padding-top: 12rem;

}
.finder-button {
    border: 10px solid #962d54 !important;
    border-radius: 13px !important;
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #e5a435, #ffcd37);
    width: 91%;
}
.finder-button:hover {
    font-family: 'Lato', sans-serif!important;
    font-size: 17px;
    font-weight: 600;
    background-image: linear-gradient(90deg, #ffcd37, #e5a435);
    color: white;
}

.herorowStyle {
    /* padding-top: 33em !important; */
    margin-left: auto !important;
    margin-right: auto !important;

}

.selectsStyle {

    border: 10px solid #962d54 !important;
    border-radius: 13px !important;
}

.hbrsStyle {

    margin-left: auto !important;
    margin-right: auto !important;
}

.submitsStyle {

    width: 32% !important;
    border: 10px solid #962d54 !important;
    border-radius: 13px !important;
}

.serviceHeaderColStyle{

    margin-right:1rem
}


@media(width:820px){


    .herorowStyle{

        padding-top: 44em !important;
    }

    .submitsStyle{

        width: 97% !important;
    }

    .serviceHeaderFormStyle{

        display: grid !important;
    }
}

@media (max-width:576px) {

    .herorowStyle {

        padding-top: 51em !important;
    }

    .submitsStyle {

        width: 29rem !important;
    }

    .serviceHeaderFormStyle {

        display: grid !important;
    }

    .serviceHeaderColStyle {


        margin-bottom: 10px
    }

}


@media(max-width:450px) {
    .submitsStyle {
        width: min(20rem,291px )!important;

    }

    .serviceHeaderColStyle{

        margin-right:0rem
    }
}

