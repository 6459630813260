.cartRowGrounds{
    background: #ffffff !important;
    margin-top: -5rem !important;
    border-radius: 100px 100px 0px 0px !important;
    width: 96%;
    padding: 80px 0;
    margin-left: auto;
    margin-right: auto;
}
.checkFormStyle {
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
    margin: 0;
    padding: 20px;
}


.checkInputStyle>input{

    width: 18rem;
    height: 4rem;
    margin-bottom: 1rem;
    border:3px solid #962d54 !important;
    border-radius: 5px;

}

.rightdate {
    background: #ffffff!important;
    box-shadow: 0px 0px 9px 0px rgb(217 217 217 / 50%);
    margin: 0;
    padding: 20px;
    font-size: 16px;
    font-family: 'Lato';
}

.DateItemStyleBtn{

    background-color:#e3a035 !important ;
    margin-left: 18rem !important;
    height: 3rem !important;
    width: 13rem !important;
    border:3px solid #962d54 !important;
}

